import {LanguageLocalizationModel} from '../models/languageModel';
import {DataType} from '../enums/dataType';

const localizationsTr = [
    {key: 'meta-default-title', value: 'Serkan DOĞANBAŞ | Rise TODO App', dataType: DataType.string},
    {key: 'meta-default-keyword', value: 'todo, todo app, serkan doğanbaş', dataType: DataType.string},
    {key: 'meta-default-description', value: 'Rise Frontend Assessment Project.', dataType: DataType.string},
    {key: 'meta-default-abstract', value: 'Rise Frontend Assessment Project.', dataType: DataType.string},
    {key: 'fetch-todo-priority-list', value: 'Durum verileri sunucudan alınıyor...', dataType: DataType.string},
    {key: 'validation-required', value: '\'{{name}}\' isimli alan boş bırakılamaz.', dataType: DataType.string},
    {key: 'validation-string-min', value: '\'{{name}}\' isimli alan değeri en az {{min}} karakterden oluşmalıdır.', dataType: DataType.string},
    {key: 'validation-string-max', value: '\'{{name}}\' isimli alan değeri en fazla {{max}} karakterden oluşmalıdır.', dataType: DataType.string},
    {key: 'validation-string-length', value: '\'{{name}}\' isimli alan değeri en en {{min}} en fazla {{max}} karakterden oluşmalıdır.', dataType: DataType.string},
    {key: 'validation-required', value: '\'{{name}}\' isimli alan boş bırakılamaz.', dataType: DataType.string},
    {key: 'error-page-title', value: 'Bir Hata Oluştu', dataType: DataType.string},
    {key: 'error-message-title', value: 'Hata Mesajı', dataType: DataType.string},
    {key: 'ignore-error', value: 'Hatayı Yoksay', dataType: DataType.string},
    {key: 'reload-application', value: 'Uygulamayı Yeniden Yükle', dataType: DataType.string},
    {key: 'connection-error-title', value: 'İnternet Bağlantısı Bulunamadı', dataType: DataType.string},
    {key: 'connection-error-content', value: 'İnternet bağlantısı bulunamadı. Lütfen bağlantınızı kontrol ediniz. Bağlantı sağlandığı zaman kaldığınız yerden devam edebilirsiniz.', dataType: DataType.string},
    {key: 'undefined-loader-text', value: 'Bilinmeyen yükleme...', dataType: DataType.string},
    {key: 'application-loader-title', value: 'Bekleyen Yükleme(ler) mevcut', dataType: DataType.string},
    {key: 'footer-repository-link-title', value: 'repository', dataType: DataType.string},
    {key: 'footer-repository-link-text', value: 'repository', dataType: DataType.string},
    {key: 'select-app-language', value: 'Dil Seçimi', dataType: DataType.string},
    {key: 'data-transfer', value: 'Veri Aktarımı', dataType: DataType.string},
    {key: 'export-data', value: 'Dışarı Aktar', dataType: DataType.string},
    {key: 'export-excel-file-name', value: 'gorev-listesi', dataType: DataType.string},
    {key: 'export-excel-worksheet-name', value: 'Görev Listesi', dataType: DataType.string},
    {key: 'todo-add-form-job-title-label', value: 'Göre Adı', dataType: DataType.string},
    {key: 'todo-add-form-job-pritiory-label', value: 'Görev Önceliği', dataType: DataType.string},
    {key: 'todo-add-form-dublicate-error-title', value: 'Hata!', dataType: DataType.string},
    {key: 'todo-add-form-dublicate-error-', value: 'Eklemek istediğiniz görev daha önce listeye eklenmiştir. Lütfen farklı bir görev tanımı deneyiniz.', dataType: DataType.string},
    {key: 'todo-add-form-submit-text', value: 'Oluştur', dataType: DataType.string},
    {key: 'todo-add-form-job-priority-chose', value: 'Seçiniz', dataType: DataType.string},
    {key: 'todo-add-form-title', value: 'Yeni Görev Oluştur', dataType: DataType.string},
    {key: 'todo-list-title', value: 'Görev Listesi', dataType: DataType.string},
    {key: 'todo-filter-chose-priority', value: 'Öncelik Durumu Seçin', dataType: DataType.string},
    {key: 'todo-filter-priority-all', value: 'Öncelik (Tümü)', dataType: DataType.string},
    {key: 'todo-table-actions', value: 'İşlemler', dataType: DataType.string},
    {key: 'todo-table-edit-action-title', value: 'Güncelle', dataType: DataType.string},
    {key: 'todo-table-delete-action-title', value: 'Sil', dataType: DataType.string},
    {key: 'todo-delete-all-item-confirm-title', value: 'Tüm görevleri silmek istiyor musunuz?', dataType: DataType.string},
    {key: 'todo-delete-all-item-confirm-content', value: 'Onay vermeniz durumunda tüm kayıtlı görevler silinecektir.', dataType: DataType.string},
    {key: 'notification-success-title', value: 'Görev Başarıyla Tamamlandı!', dataType: DataType.string},
    {key: 'todo-delete-all-item-success', value: 'Kayıtlı tüm görevler başarıyla silinmiştir.', dataType: DataType.string},
    {key: 'todo-delete-all-item', value: 'Tüm Veriyi Temizle', dataType: DataType.string},
    {key: 'confirm-ok-button-text', value: 'Onayla', dataType: DataType.string},
    {key: 'confirm-cancel-button-text', value: 'Vazgeç', dataType: DataType.string},
    {key: 'todo-edit-form-title', value: 'Görevi Düzenle', dataType: DataType.string},
    {key: 'modal-save-button-text', value: 'Kaydet', dataType: DataType.string},
    {key: 'todo-delete-item-confirm-title', value: 'Seçmiş olduğunuz görevi silmek istiyor musunuz?', dataType: DataType.string},
    {key: 'todo-delete-item-confirm-content', value: 'Seçmiş olduğunuz görev kalıcı olarak silinecektir.', dataType: DataType.string},
    {key: 'todo-delete-item-success', value: '\'{{todo_name}}\' isimli görev başarı ile kaldırılmıştır.', dataType: DataType.string},
    {key: 'todo-update-item-success', value: '\'{{todo_name}}\' isimli görev önceliği başarı ile değiştirilmiştir.', dataType: DataType.string},
    {key: 'todo-add-date', value: 'Ekleme Tarihi', dataType: DataType.string}

] as LanguageLocalizationModel[];

const localizationsEn = [
    {key: 'meta-default-title', value: 'Serkan DOĞANBAŞ | Rise TODO App', dataType: DataType.string},
    {key: 'meta-default-keyword', value: 'todo, todo app, serkan doğanbaş', dataType: DataType.string},
    {key: 'meta-default-description', value: 'Rise Frontend Assessment Project.', dataType: DataType.string},
    {key: 'meta-default-abstract', value: 'Rise Frontend Assessment Project.', dataType: DataType.string},
    {key: 'fetch-todo-priority-list', value: 'Receiving priority data from server...', dataType: DataType.string},
    {key: 'validation-required', value: 'The field named \'{{name}}\' cannot be left blank.', dataType: DataType.string},
    {key: 'validation-string-min', value: 'The field value \'{{name}}\' must be at least {{min}} characters.', dataType: DataType.string},
    {key: 'validation-string-max', value: 'The field value named \'{{name}}\' must be a maximum of {{max}} characters.', dataType: DataType.string},
    {key: 'validation-string-length', value: 'The field value named \'{{name}}\' must be at least {{min}} and at most {{max}} characters.', dataType: DataType.string},
    {key: 'validation-required', value: 'The field named \'{{name}}\' cannot be left blank.', dataType: DataType.string},
    {key: 'error-page-title', value: 'Something Went Wrong', dataType: DataType.string},
    {key: 'error-message-title', value: 'Error Message', dataType: DataType.string},
    {key: 'ignore-error', value: 'Ignore Error', dataType: DataType.string},
    {key: 'reload-application', value: 'Reload Application', dataType: DataType.string},
    {key: 'connection-error-title', value: 'Internet Connection Not Found', dataType: DataType.string},
    {key: 'connection-error-content', value: 'Internet connection not found. Please check your connection. Once the connection is established, you can continue where you left off.', dataType: DataType.string},
    {key: 'undefined-loader-text', value: 'Unknown install...', dataType: DataType.string},
    {key: 'application-loader-title', value: 'Pending Download(s) available', dataType: DataType.string},
    {key: 'footer-repository-link-title', value: 'repository', dataType: DataType.string},
    {key: 'footer-repository-link-text', value: 'repository', dataType: DataType.string},
    {key: 'select-app-language', value: 'Select Language', dataType: DataType.string},
    {key: 'data-transfer', value: 'Data Transfer', dataType: DataType.string},
    {key: 'export-data', value: 'Export Data', dataType: DataType.string},
    {key: 'export-excel-file-name', value: 'job-list', dataType: DataType.string},
    {key: 'export-excel-worksheet-name', value: 'Job List', dataType: DataType.string},
    {key: 'todo-add-form-job-title-label', value: 'Job Name', dataType: DataType.string},
    {key: 'todo-add-form-job-pritiory-label', value: 'Job Priority', dataType: DataType.string},
    {key: 'todo-add-form-dublicate-error-title', value: 'Error!', dataType: DataType.string},
    {key: 'todo-add-form-dublicate-error-', value: 'The job you want to add has already been added to the list. Please try a different job description.', dataType: DataType.string},
    {key: 'todo-add-form-submit-text', value: 'Create', dataType: DataType.string},
    {key: 'todo-add-form-job-priority-chose', value: 'Choose', dataType: DataType.string},
    {key: 'todo-add-form-title', value: 'Create New Job', dataType: DataType.string},
    {key: 'todo-list-title', value: 'Job List', dataType: DataType.string},
    {key: 'todo-filter-chose-priority', value: 'Select Priority', dataType: DataType.string},
    {key: 'todo-filter-priority-all', value: 'Priority (All)', dataType: DataType.string},
    {key: 'todo-table-actions', value: 'Actions', dataType: DataType.string},
    {key: 'todo-table-edit-action-title', value: 'Edit', dataType: DataType.string},
    {key: 'todo-table-delete-action-title', value: 'Delete', dataType: DataType.string},
    {key: 'todo-delete-all-item-confirm-title', value: 'Do you want to delete all job?', dataType: DataType.string},
    {key: 'todo-delete-all-item-confirm-content', value: 'All saved job will be deleted if you give consent.', dataType: DataType.string},
    {key: 'notification-success-title', value: 'Mission Successfully Completed!', dataType: DataType.string},
    {key: 'todo-delete-all-item-success', value: 'All saved jobs have been deleted successfully.', dataType: DataType.string},
    {key: 'todo-delete-all-item', value: 'Clear All Data', dataType: DataType.string},
    {key: 'confirm-ok-button-text', value: 'Approve', dataType: DataType.string},
    {key: 'confirm-cancel-button-text', value: 'Cancel', dataType: DataType.string},
    {key: 'todo-edit-form-title', value: 'Edit Job', dataType: DataType.string},
    {key: 'modal-save-button-text', value: 'Save', dataType: DataType.string},
    {key: 'todo-delete-item-confirm-title', value: 'Do you want to delete the selected job?', dataType: DataType.string},
    {key: 'todo-delete-item-confirm-content', value: 'The job you selected will be permanently deleted.', dataType: DataType.string},
    {key: 'todo-delete-item-success', value: '\'Job named {{todo_name}}\' has been removed successfully.', dataType: DataType.string},
    {key: 'todo-update-item-success', value: 'The job priority named \'{{todo_name}}\' has been successfully changed.', dataType: DataType.string},
    {key: 'todo-add-date', value: 'Add Date', dataType: DataType.string}

] as LanguageLocalizationModel[];

export {localizationsTr, localizationsEn};
